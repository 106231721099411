/* General Header Styles */
.custom-navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Navbar when transparent */
.custom-navbar.navbar-dark {
  background-color: transparent !important;
}

/* Navbar when sticky (white background) */
.custom-navbar.navbar-light.bg-white {
  background-color: rgba(235, 242, 250, 0.85) !important; /* Light blue background */
  backdrop-filter: blur(10px);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Navbar brand styling */
.custom-navbar .navbar-brand {
  display: flex;
  align-items: center;
  padding: 5px;
  flex-grow: 1;
  color: #033F63; /* Dark text color */
  text-shadow: none;
}

.custom-navbar.navbar-dark .navbar-brand {
  color: #EBF2FA; /* Light text color */
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

/* Center the nav items */
.custom-navbar .desktop-menu {
  display: flex;
  justify-content: center;
  position: relative;
  margin-left: auto;
}

/* Space between the navbar items */
.custom-navbar .desktop-menu .nav-link {
  font-family: 'Arial', sans-serif;
  transition: color 0.3s ease-in-out, padding 0.3s ease-in-out;
  padding: 8px 15px;
  color: #033F63 !important; /* Dark text color */
  text-decoration: none;
}

/* Active state color adjustment for non-transparent mode */
.custom-navbar.navbar-light.bg-white .desktop-menu .nav-link.active {
  color: #199297 !important; /* Blue color for active state */
}

/* Transparent mode: set text color to white */
.custom-navbar.navbar-dark .desktop-menu .nav-link {
  color: #EBF2FA !important;
}

.custom-navbar.navbar-dark .desktop-menu .nav-link.active {
  color: #199297 !important;
}

/* Hover Effect: Inverse Colors */
.custom-navbar .desktop-menu .nav-link:hover {
  color: #FFFFFF !important;
  background-color: #033F63 !important;
  border-radius: 4px;
}

/* Independent Slider for Active Link */
.custom-navbar .nav-slider {
  position: absolute;
  bottom: 0;
  height: 3px;
  background-color: #199297;
  transition: width 0.3s ease, left 0.3s ease;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 1024px) {
  .custom-navbar .desktop-menu .nav-link {
    padding: 6px 10px;
    font-size: 14px;
  }
}

@media (max-width: 800px) {
  .custom-navbar .desktop-menu {
    display: none;
  }

  .custom-navbar .navbar-brand {
    flex-grow: 1;
  }

  /* Full-width mobile menu */
  .MuiPaper-root.MuiMenu-paper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: none;
    margin: 0;
    padding: 0;
    background-color: rgba(235, 242, 250, 0.85) !important; /* Light blue background to match the image */
    backdrop-filter: blur(10px);
    z-index: 1100;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Slight shadow */
  }

  /* Center menu items and adjust their appearance */
  .MuiMenu-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .MuiMenuItem-root {
    width: 100%;
    text-align: left;
    padding: 16px 24px;
    color: #033F63 !important; /* Dark text color */
    font-size: 18px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Light border between items */
    transition: all 0.3s ease;
  }

  .MuiMenuItem-root:hover {
    background-color: rgba(0, 0, 0, 0.1) !important; /* Light background on hover */
    color: #FFFFFF !important; /* White text color on hover */
  }

  /* Hide the slider in mobile view */
  .custom-navbar .nav-slider {
    display: none;
  }
}
