/* General About Section */
.about-section {
  position: relative;
  background-color: #000000; /* Use a darker black version */
  color: #1a1a1a;
  padding: 50px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  scroll-margin-top: 80px;
  min-height: 80vh;
}

/* About Header and Tabs */
.about-header h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #ffffff; /* White for better contrast */
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-button {
  background: none;
  border: none;
  color: #888888;
  font-size: 1.2rem;
  margin: 0 10px;
  cursor: pointer;
  padding: 10px 20px;
  position: relative;
  transition: color 0.3s, transform 0.3s;
  outline: none; /* Remove the default focus outline */
}

.tab-button.active {
  color: #ffffff; /* Active tab color */
  border-bottom: 2px solid #199297; /* Blue underline for active tab */
  transform: scale(1.1); /* Slight scale animation on active tab */
}

.tab-button::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #199297; /* Blue color for underline */
  transition: width 0.3s ease, left 0.3s ease;
}

.tab-button:hover::before,
.tab-button.active::before {
  width: 100%; /* Expand the underline */
  left: 0;
}

.tab-button:hover {
  color: #ffffff; /* Hover state color */
}

/* Service section styles */
.services-images {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.service-item {
  position: relative;
  width: calc(25% - 20px);
  margin: 10px;
  overflow: hidden;
}

.service-item img {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border-radius: 10px;
  filter: blur(3px) brightness(0.6); /* Apply blur and darken images */
}

.service-caption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  color: #ffffff; /* White text color */
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(16, 16, 16, 0.7); /* Match the darker black background with 70% transparency */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.9); /* Darker text shadow */
  text-align: center;
  transition: background-color 0.3s ease, opacity 0.3s ease;
  overflow: hidden; /* To avoid content overflow during animation */
}

.service-caption h5, .service-caption p {
  margin: 0;
  position: absolute;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.service-caption h5 {
  z-index: 2;
  opacity: 1;
  transform: translateY(0);
}

.service-caption p {
  opacity: 0;
  transform: translateY(20px); /* Initially move subtext down */
}

.service-item:hover .service-caption {
  background-color: rgba(25, 146, 151, 0.7); /* Greenish overlay with 70% opacity */
}

.service-item:hover .service-caption h5 {
  opacity: 0; /* Fade out heading */
  transform: translateY(-20px); /* Move heading up */
}

.service-item:hover .service-caption p {
  opacity: 1; /* Fade in subtext */
  transform: translateY(0); /* Move subtext up */
}

.services-images.hidden {
  display: none;
}

.services-images.active {
  display: flex; /* Ensure it is flex to match the flexbox layout */
  animation: fadeIn 0.3s ease-in-out; /* Fade-in animation */
}

/* Fade-in Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile-specific styles for text reveal */
@media (max-width: 767.98px) {
  .service-caption {
    background-color: rgba(16, 16, 16, 0.7); /* Default background color with 70% opacity */
    transition: background-color 0.3s ease;
  }

  .service-caption h5 {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.8s ease, transform 0.8s ease;
  }

  .service-caption p {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease 1s, transform 0.8s ease 1s; /* 1s delay added */
  }

  .service-item.in-view .service-caption h5 {
    opacity: 0;
    transform: translateY(-20px); /* Move title up */
    transition-delay: 1s; /* Delay the fade-out by 1 second */
  }

  .service-item.in-view .service-caption p {
    opacity: 1;
    transform: translateY(0); /* Move description up */
  }

  .service-item.in-view .service-caption {
    background-color: rgba(25, 146, 151, 0.7); /* Greenish color with 70% opacity during transition */
    transition-delay: 1.6s; /* Delay the background color change until after the description appears */
  }
}

/* Media Queries for Responsive Layout */
@media (min-width: 1200px) {
  .services-images {
    gap: 30px;
  }
  .service-item {
    width: calc(25% - 30px);
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .services-images {
    gap: 30px;
  }
  .service-item {
    width: calc(33.33% - 30px);
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .tabs {
    flex-direction: row;
  }
  .tab-button {
    margin: 5px 10px;
  }
  .services-images {
    gap: 20px;
    flex-wrap: wrap;
  }
  .service-item {
    width: calc(50% - 20px);
  }
}

@media (max-width: 767.98px) {
  .tabs {
    flex-direction: column;
  }
  .tab-button {
    margin: 5px 0;
  }
  .services-images {
    flex-direction: column;
  }
  .service-item {
    width: 90%;
    margin: 10px auto;
  }
  .about-section {
    height: auto; /* Extend section height based on content for mobile view */
  }
  .service-caption {
    padding: 10px;
    font-size: 0.9rem; /* Adjust font size for mobile */
  }
}

/* For larger screens */
@media (min-width: 1200px) {
  .about-header h2 {
    font-size: 3rem;
  }
  .about-header p {
    font-size: 1.2rem;
  }
  .service-item img {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    object-fit: cover;
  }
}

/* For medium screens */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .about-section {
    min-height: auto;
  }
  .about-header h2 {
    font-size: 2.5rem;
  }
  .about-header p {
    font-size: 1rem;
  }
  .service-item img {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    object-fit: cover;
  }
}

/* For smaller screens */
@media (min-width: 768px) and (max-width: 991.98px) {  
  .about-section {
    min-height: auto;
  }

  .about-header h2 {
    font-size: 2rem;
  }
  .about-header p {
    font-size: 0.9rem;
  }
  .service-item img {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    object-fit: cover;
  }
}

/* For mobile screens */
@media (max-width: 767.98px) {
  .about-section {
    min-height: auto;
  }

  .about-header h2 {
    font-size: 1.5rem;
  }
  .about-header p {
    font-size: 0.9rem;
  }
  .service-item img {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    object-fit: cover;
  }
  .service-caption {
    padding: 10px;
    font-size: 0.9rem; /* Adjust font size for mobile */
  }
}
