/* Root Variables */
:root {
  --surface-50: #FFFFFF;
  --surface-100: #F0F0F0;
  --surface-200: #D9D9D9;
  --surface-300: #BFBFBF;
  --surface-400: #A6A6A6;
  --surface-500: #8C8C8C;
  --surface-600: #737373;
  --surface-700: #595959;
  --surface-800: #404040;
  --surface-900: #262626;
  --primary-50: #FFFFFF;
  --primary-100: #FFFFFF; /* Light Gray for Button Hover */
  --primary-200: #E3D1B8; /* Paper-like texture color for Button Background */
  --primary-300: #C8B29A; /* Darker Paper-like color for Active State */
}

/* Hero Section */
#hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;
  color: var(--surface-50);
  overflow: hidden;
  padding: 0;
  margin: 0;
  background-color: var(--surface-900);
}

/* Background Images Container */
.background-images {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
}

/* Individual Background Images */
.background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;
  transform: translateX(-100px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.background-image.active {
  opacity: 1;
  transform: translateX(0);
}

/* Background Blur */
.background-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(5px);
  z-index: -1;
}

/* Overlay */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

/* Hero Content */
.hero-content {
  position: relative;
  z-index: 2;
  padding: 20px;
  text-align: left;
  color: white;
  max-width: 1000px; /* Increased max-width to ensure enough space */
  margin: 0 auto;
  padding-bottom: 100px;
  margin-left: 50px;
}

/* New Slide-in Animation */
@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.hero-title,
.hero-text {
  opacity: 0;
  transform: translateX(-100px);
  animation: slideInFromLeft 0.8s ease-out forwards;
}

/* Title Styling */
.hero-title {
  animation-delay: 0.1s;
  font-size: 4vw; /* Reduced font size to fit long text */
  max-width: 100%; /* Ensure the title doesn’t overflow the container */
  color: white !important;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowed text */
  line-height: 1.2; /* Adjust line-height for better spacing */
}

/* Text Styling */
.hero-text {
  animation-delay: 0.3s;
  font-size: 2.5vw;
  font-weight: 700;
  line-height: 1.6;
  color: white !important;
  max-width: 100%;
}

/* Button */
.hero-buttons {
  position: absolute;
  bottom: 30px;
  left: 50px;
  z-index: 2;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.hero-buttons .MuiButton-root {
  background-color: #E3D1B8 !important;
  color: #262626 !important;
  padding: 10px 20px !important;
  border-radius: 25px !important;
  font-size: 1rem !important;
  font-weight: 700 !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease !important;
  margin: 0 10px !important;
}

.hero-buttons .MuiButton-root:hover {
  transform: scale(1.03) !important;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3) !important;
  background: var(--primary-100) !important;
}

.hero-buttons .MuiButton-root:active {
  background: var(--primary-300) !important;
}

/* Contact Us Button */
.hero-buttons .contact-us-button {
  background-color: transparent !important; /* Transparent background */
  color: #ffffff !important; /* White font */
  border: 2px solid #ffffff !important; /* White border */
}

.hero-buttons .contact-us-button:hover {
  background-color: #ffffff !important; /* White background on hover */
  color: #000000 !important; /* Black font on hover */
}

/* Learn More Button */
.hero-buttons .learn-more-button {
  background-color: #000000 !important; /* Black background */
  color: #ffffff !important; /* White font */
}

.hero-buttons .learn-more-button:hover {
  background-color: #ffffff !important; /* White background on hover */
  color: #000000 !important; /* Black font on hover */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .hero-title {
    font-size: 6vw; /* Adjusted font size to fit the full text */
    white-space: normal; /* Allow wrapping */
  }

  .hero-text {
    font-size: 3.5vw;
    white-space: normal; /* Allow wrapping */
  }

  .hero-buttons .MuiButton-root {
    padding: 8px 16px !important;
    font-size: 0.9rem !important;
    border-radius: 20px !important;
  }
}

@media (max-width: 480px) {
  .hero-title {
    font-size: 7vw; /* Further adjust for smaller screens */
    white-space: normal; /* Allow wrapping */
  }

  .hero-text {
    font-size: 4.5vw;
    white-space: normal; /* Allow wrapping */
  }

  .hero-buttons .MuiButton-root {
    padding: 6px 14px !important;
    font-size: 0.85rem !important;
    border-radius: 18px !important;
  }
}

/* Larger Screen Adjustments */
@media (min-width: 1024px) {
  .hero-title {
    font-size: 4vw; /* Adjust font size for larger screens */
  }

  .hero-text {
    font-size: 2vw;
  }

  .hero-buttons .MuiButton-root {
    padding: 14px 28px !important;
    font-size: 1.1rem !important;
    border-radius: 30px !important;
  }
}

@media (min-width: 1440px) {
  .hero-title {
    font-size: 3.5vw; /* Further reduce font size for very large screens */
  }

  .hero-text {
    font-size: 2.2vw;
  }

  .hero-buttons .MuiButton-root {
    padding: 16px 32px !important;
    font-size: 1.2rem !important;
    border-radius: 35px !important;
  }
}
