/* General Styles */

section {
  scroll-margin-top: 80px; 
}

.testimonials {
  scroll-margin-top: 80px;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #287094;
}

.testimonials .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Testimonial Item Styles */
.testimonials-item {
  background-color: #f6f6f6;
  padding: 20px;
  border-radius: 30px;
  color: #012641;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  text-align: center;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  min-height: 300px;
  position: relative;
  transform: translateX(-100%);
  opacity: 0;
}

.testimonials-item-active {
  transform: translateX(0);
  opacity: 1;
}

.testimonials-item img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: 0 auto 20px auto;
  object-fit: cover;
}

.testimonials-item .info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.testimonials-item .text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.testimonials-item h3.name {
  font-size: 1.8rem;
  margin: 0;
}

.testimonials-item span.job {
  font-size: 1.2rem;
  color: #666;
}

.testimonials-item p {
  margin-top: 10px;
  color: #666;
  flex-grow: 1;
  font-size: 1.1rem;
}

/* Swiper Styles */
.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  filter: blur(5px);
  opacity: 0.5;
  transition: filter 1s ease-in-out, opacity 1s ease-in-out;
}

.swiper-slide-active {
  filter: blur(0);
  opacity: 1;
}

.testimonials-content {
  overflow: hidden;
  padding: 0 15px;
  position: relative;
  width: 100%;
}

.swiper-pagination-bullet {
  background: #00cc66;
}

.testimonials-slider {
  position: relative;
}

.swiper-pagination {
  bottom: 20px;
}

.see-more-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  margin: 1rem 0;
  background-color: #287094;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
  border: none;
  cursor: pointer;
}

.see-more-button:hover {
  background-color: #000000;
  color: #ffffff;
}

.see-more-button i {
  margin-right: 8px;
}

.swiper-button-prev,
.swiper-button-next {
  color: #287094;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  color: #000000;
}

/* Pop-up Container Styles */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1050;
  background: rgba(0, 0, 0, 0.8);
  overflow: auto;
  padding: 20px;
}

.popup-content {
  background: #fff;
  border-radius: 15px;
  padding: 40px;
  max-width: 700px;
  width: 90%;
  height: 80vh; /* Fixed height for the popup */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  margin: 60px auto 20px auto;
  overflow-y: auto; /* Enable scrolling if content overflows */
}

.popup-content h4 {
  color: #333; 
  font-size: 1.2rem; 
  margin-top: 10px; 
  margin-bottom: 20px; 
}


.popup-content h2 {
  margin-top: 0;
  font-family: Arial, sans-serif;
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.popup-content p {
  margin-top: 20px;
  font-size: 1.2rem;
  color: #333;
  line-height: 1.5;
}

.popup-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
  object-fit: cover;
}

.popup-content .close-popup {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  font-weight: bold;
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
}

.popup-content .close-popup:hover {
  color: #ff6666;
}

/* Button Styles */
.linkedin-link {
  display: inline-block;
  font-size: 1.1rem;
  color: #0077b5;
  text-decoration: none;
  border: 1px solid #0077b5;
  padding: 12px 24px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  margin-top: 40px;
  margin-right: 10px; /* Add margin between buttons */
}

.linkedin-link:hover {
  text-decoration: none;
  background-color: #0077b5;
  color: #ffffff;
}

.github-link {
  display: inline-block;
  font-size: 1.1rem;
  color: #287094;
  text-decoration: none;
  border: 1px solid #287094;
  padding: 12px 24px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  margin-top: 40px;
  margin-right: 10px; /* Add margin between buttons */
}

.github-link:hover {
  text-decoration: none;
  background-color: #287094;
  color: #ffffff;
}

/* Ensure popup covers the entire viewport on small screens */
@media screen and (max-width: 700px) {
  .popup-container {
    padding: 10px;
  }

  .popup-content {
    padding: 20px;
    max-width: 90%;
  }

  .popup-content h2 {
    font-size: 1.5rem;
  }

  .popup-content p {
    font-size: 1rem;
  }

  .popup-img {
    width: 150px;
    height: 150px;
  }

  .linkedin-link,
  .github-link {
    font-size: 1rem;
    padding: 8px 16px;
    margin-top: 20px;
    margin-right: 10px;
  }
}

/* Media Queries */
@media (max-width: 768px) {
  .testimonials-item {
    padding: 20px;
  }

  .testimonials-item img {
    width: 60px;
    height: 60px;
  }

  .testimonials-item h3.name {
    font-size: 1.5rem;
  }

  .testimonials-item span.job {
    font-size: 1rem;
  }

  .testimonials-item p {
    margin-top: 15px;
    font-size: 1rem;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none  !important;
  }
}