#career {
  scroll-margin-top: 60px;
  background-color: #000000; 
  animation: fadeIn 1s ease-in-out forwards; 
  opacity: 0;
}

.career-container {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;  
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
}

.career-info {
  flex: 1;
  padding: 40px;
  background-color: #000000;
  color: #ffffff;
  border-radius: 10px 0 0 10px;
  min-width: 250px;
}

.career-info h4 {
  font-size: 18px;
  margin-bottom: 20px;
}

.career-info ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.career-info ul li {
  margin-bottom: 10px;
  font-size: 14px;
}

.social-links {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.social-links a {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #333;
  color: #fff;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  transition: background-color 0.3s;
}

.social-links a:hover {
  background-color: #555;
}

.career-form {
  flex: 2;
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 10;
  min-width: 300px;
}

.career-form .form-header {
  font-size: 18px;
  margin-bottom: 20px;
}

.career-form .form-group {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.career-form .form-group input,
.career-form .form-group textarea {
  width: calc(50% - 10px);
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
}

.career-form .form-group input.full-width,
.career-form .form-group textarea.full-width {
  width: 100%;
}

.career-form .form-group input:focus,
.career-form .form-group textarea:focus {
  border-color: #007bff;
  outline: none;
}

.career-form .form-group textarea {
  resize: none;
  height: 100px;
}

.career-form .btn {
  background-color: #000;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  width: 100%;
}

.career-form .btn:hover {
  background-color: #ffffff;
  color: #000000;
}

/* Footer Styling */
footer {
  background-color: #000000;  
  padding: 60px 10%;
  color: #f6f6f6;
}

.footer-cols {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px;
  flex-wrap: wrap;
}

.footer-col {
  flex: 1;
  min-width: 200px;
}

.footer-col h4 {
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.footer-col ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-col ul li {
  margin-bottom: 10px;
  font-size: 14px;
}

.job-application-header {
  text-align: center; /* Center the text */
  margin: 40px 0; /* Space above and below the heading */
}

.job-application-header span {
  font-size: 24px; /* Larger font for the heading */
  font-weight: bold; /* Make it bold */
  color: #ffffff; /* White color to match the theme */
  text-transform: uppercase; /* Uppercase styling */
  display: inline-block; /* Make sure the span behaves like a block element */
  padding: 10px 20px; /* Add some padding */
  background-color: #000000; /* Match the background color with the rest */
  border-radius: 5px; /* Slight rounding for aesthetics */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Add some shadow for depth */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .career-container {
    flex-direction: column;
  }

  .career-info {
    border-radius: 10px 10px 0 0;
    text-align: center;
  }

  .career-form {
    border-radius: 10px;
  }

  .career-form .form-group {
    flex-direction: column;
    gap: 10px;
  }

  .career-form .form-group input,
  .career-form .form-group textarea {
    width: 100%;
  }

  .footer-cols {
    flex-direction: column;
  }

  .footer-col {
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .career-container {
    padding: 10px;
  }

  .career-info {
    padding: 20px;
  }

  .career-form {
    padding: 20px;
  }

  .footer-cols {
    flex-direction: column;
  }

  .footer-col {
    width: 100%;
  }
}
