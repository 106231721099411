.slider {
  width: 100%;
  height: 80vh;
  position: relative;
  display: flex;
  background-color: #033f63; /* Darker version of the blue from the palette */
}

.slider .controls {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.slider .controls > div {
  width: 50px;
  height: 50px;
  background-color: rgba(235, 242, 250, 0.8); /* Light blue/white from the palette */
  color: #033f63; /* Darker blue for the arrow */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 5px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.slider .controls > div:hover {
  background-color: #1b828d; /* Slightly darker shade for hover */
}

.slider .wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.slider .wrapper .left,
.slider .wrapper .right {
  width: 50%;
  overflow: hidden;
  position: relative;
}

.slider .wrapper .left > div,
.slider .wrapper .right > div {
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
  position: absolute;
  top: 0;
}

.slider .wrapper .left > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  background: #1b828d; /* Adjusted to a slightly darker blue */
  color: #ffffff;
}

.slider .wrapper .left > div h2 {
  font-size: 50px;
  margin-bottom: 10px;
}

.slider .wrapper .left > div p {
  font-size: 18px;
}

.slider .wrapper .right > div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .slider {
    flex-direction: column;
  }

  .slider .controls {
    flex-direction: row;
    top: 90%;  /* Adjust position to be closer to the bottom */
    transform: translate(-50%, -50%);
  }

  .slider .controls > div {
    margin: 0 5px;
    width: 35px;
    height: 35px;
  }

  .slider .wrapper {
    flex-direction: column;
  }

  .slider .wrapper .left,
  .slider .wrapper .right {
    width: 100%;
    height: 50%;
  }

  .slider .wrapper .left > div,
  .slider .wrapper .right > div {
    height: 100%;
    top: 0;
  }
}

@media (max-width: 480px) {
  .slider {
    flex-direction: column;
  }

  .slider .controls {
    display: flex; /* Ensure controls are displayed */
    flex-direction: row;
    top: 95%; /* Further adjust position */
    transform: translate(-50%, -50%);
  }

  .slider .controls > div {
    margin: 0 3px;
    width: 25px; /* Smaller button size */
    height: 25px; /* Smaller button size */
  }

  .slider .wrapper {
    flex-direction: column;
  }

  .slider .wrapper .left,
  .slider .wrapper .right {
    width: 100%;
    height: 50%;
  }

  .slider .wrapper .left > div,
  .slider .wrapper .right > div {
    height: 100%;
    top: 0;
  }
}
