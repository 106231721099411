.editor-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .title-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    font-size: 18px;
  }
  
  .actions {
    margin-top: 20px;
  }
  
  .btn {
    padding: 10px 20px;
    margin-right: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .btn:hover {
    background-color: #0056b3;
  }

  .ql-container {
  min-height: 300px;
  }

  .ql-toolbar {
  order: 2;
  border-top: 1px solid #ccc;
  border-bottom: none;
  }
